import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import {
  Button, Col, Form, Select, Row, Spin, Switch,
} from 'antd';
import * as qs from 'query-string';


import moment from 'moment';
import amsAPI from '../../apis/amsAPI';

import 'antd/dist/antd.css';
import './Home.css';

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

class ReportsHomeByGathering extends Component {
  state = {
    roles: [],
    gatherings: [],
    gatheringTypes: [],
    ministries: [],
    selectedGathering: '',
    selectedMinistry: '',
    selectedRoles: [],
    isGenerateAbsentees: false,
    loadingGatherings: false,
  };

  componentDidMount() {
    this.getRequireInfoFromAPI();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      this.getRequireInfoFromAPI();
    }
  }

  getRequireInfoFromAPI = async () => {
    this.setState({ loadingGatherings: true });
    this.callApi(`/ams/gathering_types`)
      .then(res => this.setState({ gatheringTypes: res.data, loading: false }))
      .catch(err => console.log(err));
    this.callApi('/ams/roles')
      .then(res => {
        this.setState({ roles: res.data })
      });
    this.callApi('/ams/ministries')
      .then(res => {
        this.setState({ ministries: res.data, loadingMinistries: false })
      });
    this.callApi(`/ams/gatherings`)
      .then(res => this.setState({ gatherings: res.gatherings, loadingGatherings: false }))
      .catch(err => console.log(err));
  }

  callApi = async (url) => {
    const response = await amsAPI.getUrl(url);
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  render() {
    const {
      gatherings, gatheringTypes, ministries, roles, selectedRoles,
      selectedGathering, selectedMinistry, loadingGatherings, isGenerateAbsentees,
    } = this.state;
    const { localeChurchId } = this.props;
    const gatheringTypesMap = {};
    gatheringTypes.forEach(item => {
      gatheringTypesMap[item.code] = item.name;
    })
    const rolesArr = [];
    roles.forEach(item => {
      rolesArr.push([item.code, item.name]);
    })
    let query = {
      gatheringId: selectedGathering,
      ministryId: selectedMinistry,
      roles: selectedRoles,
      localeChurchId: localeChurchId,
      isGenerateAbsentees,
    }
    let url = isGenerateAbsentees
      ? "/reports/locale_attendance_by_gathering_absentees"
      : "/reports/locale_attendance_by_gathering";

    if (loadingGatherings) {
      return (
        <div className="wrap">
          <div className="extraContent">
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={12} style={{ textAlign: "center" }}>
                <Spin size="large" />
              </Col>
            </Row>
          </div>
        </div>
      )
    }

    return (
      <div className="wrap">
        <div className="extraContent">
          <Row type="flex" justify="center">
          <Col xs={24} sm={24} md={24} lg={12}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <h3>Gathering Report Page</h3>
            </Col>
          </Row>
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={8}>
              <h4>Please select the gathering you would like to generate a report.</h4>
            </Col>
          </Row>
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={8}>
              <Form {...formItemLayout}>
                <Form.Item label="Gathering:">
                  <Select
                      showSearch
                      placeholder="Select the gathering"
                      dropdownMatchSelectWidth={false}
                      onChange={value => this.setState({ selectedGathering: value })}
                      value={selectedGathering}
                    >
                    {gatherings.map(item =>
                      <Option key={item._id} value={item._id}>
                        {moment(item.startDateTime).format("MMM.DD(ddd), YYYY h:mmA")} {gatheringTypesMap[item.name]} ({item.type})
                      </Option>
                    )}
                  </Select>
                </Form.Item>
                <Form.Item label="Roles">
                  <Select
                    placeholder="Please select roles"
                    dropdownMatchSelectWidth={false}
                    onChange={value => this.setState({ selectedRoles: value })}
                    allowClear={true}
                    mode="multiple"
                  >
                    {rolesArr.map(([id, name]) =>
                      <Option key={id} value={id}>{name}</Option>
                    )}
                  </Select>
                </Form.Item>
                <Form.Item label="Ministry">
                  <Select
                    placeholder="Select a ministry"
                    dropdownMatchSelectWidth={false}
                    allowClear={true}
                    onChange={value => this.setState({ selectedMinistry: value })}
                  >
                    {ministries.map(item => {
                      return <Option key={item._id} value={item._id}>{item.name}</Option>
                    })}
                  </Select>
                </Form.Item>
                <Form.Item label="Generate Absentees">
                  <Switch
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                    onChange={(checked) => this.setState({ isGenerateAbsentees: checked })}
                  />
                </Form.Item>
              </Form>
            </Col>
          </Row>
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={8}>
              <NavLink to={`${url}?${qs.stringify(query)}`}>
                <Button
                  block
                  type="primary"
                  disabled={ !selectedGathering }
                >
                  Next
                </Button>
              </NavLink>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default ReportsHomeByGathering;
