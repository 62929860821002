import React, { Component } from 'react';
import { PageHeader, Row, Col, Icon, Card } from 'antd';
import { NavLink } from 'react-router-dom';

import 'antd/dist/antd.css';
import './Home.css';

class SecHome extends Component {

  render() {
    const { userInfo } = this.props;
    const name = localStorage.getItem('name') ? localStorage.getItem('name') : "Guest";
    const isDisplayAmsSec = userInfo.roles.includes('lsc');
    const isDisplayZoomLogs = userInfo.roles.includes('zsec');
    return (
      <PageHeader
        title={`Welcome ${name}, what would you like to do today?`}
      >
        <div className="wrap">
          <div className="extraContent">
            <Row gutter={16} style={{margin: "10px"}}>
              {isDisplayAmsSec &&
              <>
                <Col xs={24} sm={24} md={24} lg={8}>
                  <Card style={{display: "flex", justifyContent: "center"}}>
                    <NavLink to="/reports">
                      <Icon type="table" style={{ fontSize: '4em', color: '#08c' }}/>
                    </NavLink>
                  </Card>
                </Col>
                <Col xs={24} sm={24} md={24} lg={8}>
                  <Card style={{display: "flex", justifyContent: "center"}}>
                    <NavLink to="/gatherings">
                      <Icon type="calendar" style={{ fontSize: '4em', color: '#08c' }}/>
                    </NavLink>
                  </Card>
                </Col>
              </>}
              {isDisplayZoomLogs &&
                <Col xs={24} sm={24} md={24} lg={8}>
                  <Card style={{display: "flex", justifyContent: "center"}}>
                    <NavLink to="/zoom">
                      <Icon type="appstore" style={{ fontSize: '4em', color: '#08c' }}/>
                    </NavLink>
                  </Card>
                </Col>
              }
            </Row>
          </div>
        </div>
      </PageHeader>

    );
  }
}

export default SecHome;
