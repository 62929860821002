import { Button, Col, DatePicker, Form, Input, message, Modal, Row, Select, Spin } from 'antd';
import { stringify } from 'query-string';
import { Component } from 'react';

import _ from "lodash";
import moment from "moment";

import amsAPI from '../../apis/amsAPI';

import 'antd/dist/antd.css';
import './Gathering.css';

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 12,
      offset: 6,
    },
  },
};


let timeout;
let currentValue;

function fetch({ value, localeChurchId }, callback) {
  if (timeout) {
    clearTimeout(timeout);
    timeout = null;
  }
  currentValue = value;

  async function fake() {
    let searchParams = new URLSearchParams({
      q: value,
      localeChurchId: localeChurchId,
    }).toString();
    amsAPI.getUrl(`/ams/members?${searchParams}`)
      .then(async response => {
        const body = await response.json();
        if (response.status !== 200) throw Error(body.message);
        return body;
      })
      .then(d => {
        if (currentValue === value) {
          callback(d.members);
        }
      });
  }

  timeout = setTimeout(fake, 300);
}

function modalError() {
  Modal.error({
    title: 'Attendance Submission Error',
    content: 'Sorry, but your attendance has already been submitted.',
  });
}

class AddGatheringAttendee extends Component {
  state = {
    member: '',
    channel: '',
    gathering: {},
    gatherings: [],
    gatheringTypes: [],
    members: [],
    attendanceDateTime: null,
    isLoading: false,
  };

  componentDidMount() {
    this.getRequiredInfoFromAPI();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      this.getRequiredInfoFromAPI();
    }
  }

  getRequiredInfoFromAPI = async () => {
    this.callApi(`/ams/gathering_types`)
      .then(res => this.setState({ gatheringTypes: res.data }))
      .catch(err => console.log(err));

    let query = { limit: 100 };
    if (this.props.scope) query.scope = this.props.scope;

    this.setState({ isLoading: true });
    this.callApi(`/ams/gatherings?${stringify(query)}`)
      .then(res => this.setState({ gatherings: res.gatherings, isLoading: false }))
      .catch(err => console.log(err));

    const { gatheringId } = this.props.match.params;
    this.getGathering(gatheringId)
      .then(res => this.setState({ gathering: res.gathering }))
      .catch(err => console.log(err));

  }

  callApi = async (url) => {
    const response = await amsAPI.getUrl(url);
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  handleSubmit = async e => {
    e.preventDefault();

    const { gatheringId } = this.props.match.params;
    amsAPI.fetchUrl(`/ams/member_attendance?gatheringId=${gatheringId}`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        memberIds: [this.state.member],
        channel: this.state.channel,
        otherChannel: this.state.otherChannel.toLowerCase(),
        userMemberId: this.props.userMemberId,
        submissionDateTime: this.state.attendanceDateTime,
      }),
    })
    .then(async res => {
      if (res.status === 200) {
        const response = await res.json();
        if (!response.error_id) {
          message.success('Attendance successfully submitted.');
          this.setState({ submitting: false });
          this.props.history.push(`/gatherings/${gatheringId}/attendees`);
        } else if (response.error_id === 'ERR001') {
          this.setState({ submitting: false });
          message.error('Wrong pass key.');
        } else if (response.error_id === 'ERR002') {
          this.setState({ submitting: false });
          message.error('All gathering links have been allocated. Please contact your assigned worker.');
        } else if (!_.isEmpty(response.error_id)) {
          this.setState({ submitting: false });
          message.error(response.error_message);
          this.getRequiredInfoFromAPI();
        }
      } else if (res.status === 422) {
        this.setState({ submitting: false, mode: '' });
        modalError();
      } else {
        const error = new Error(res.error);
        throw error;
      }
    })
    .catch(err => {
      console.error(err);
      this.setState({ submitting: false });
      message.error(`Error submitting attendance. ${ err ? err.message : ''}`);
    });

  };

  handleGatheringChange = (gatheringId) => {
    this.getGathering(gatheringId)
      .then(async res => {
        const { gathering } = res;

        this.setState({
          gathering,
        });
      })
      .catch(err => console.log(err));
  }

  getGathering = async (_id) => {
    const response = await amsAPI.getUrl(`/ams/gatherings/${_id}`)
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  handleSearch = value => {
    if (value) {
      const { localeChurchId } = this.props;
      fetch({value, localeChurchId}, data => {
        this.setState({ members: data })
      });
    } else {
      this.setState({ members: [] });
    }
  };

  handleChange = member => {
    this.setState({ member });
  };

  render() {
    const { gatheringId } = this.props.match.params;
    const {
      channel, attendanceDateTime, isLoading,
      gatherings, gatheringTypes, members, member, gathering,
    } = this.state;
    const { channels } = gathering;
    const gatheringTypesMap = {};
    gatheringTypes.forEach(item => {
      gatheringTypesMap[item.code] = item.name;
    })

    const disableSubmit = _.isEmpty(channel) || !attendanceDateTime || _.isEmpty(member);

    return (
      <div className="wrap">
        <div className="extraContent">
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={18}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <h2>Gathering Form</h2>
            </Col>
          </Row>
          {isLoading ?
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={12} style={{ textAlign: "center" }}>
                <Spin size="large" />
              </Col>
            </Row>
          :
            <>
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={18}>
                <Form {...formItemLayout}>
                  <Form.Item
                    label="Gathering"
                  >
                    <Select
                      showSearch
                      placeholder="Select a gathering"
                      dropdownMatchSelectWidth={false}
                      style={{ width: 240 }}
                      onChange={this.handleGatheringChange}
                      defaultValue={gatheringId}
                      disabled={true}
                    >
                      {gatherings.map(item =>
                      <Option key={item._id} value={item._id}>
                        {moment(item.startDateTime).format("MMM.DD(ddd), YYYY h:mmA")} {gatheringTypesMap[item.name]} ({item.type})
                      </Option>
                      )}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    showSearch
                    placeholder={"Input member name or id"}
                    label="Member"
                    validateStatus={_.isEmpty(member) ? "error" : ""}
                    help={_.isEmpty(member) ? "Please input member" : ""}
                  >
                    <Select
                      showSearch
                      placeholder={"Input member name or id"}
                      dropdownMatchSelectWidth={false}
                      style={{ width: 240 }}
                      optionFilterProp="value"
                      defaultActiveFirstOption={false}
                      showArrow={false}
                      filterOption={false}
                      onSearch={this.handleSearch}
                      onChange={this.handleChange}
                      notFoundContent={null}
                      value={member}
                      allowClear={true}
                    >
                      {members.map(item => {
                        return (
                          <Option key={item._id} value={item._id}>
                            {`${item.name}`}
                          </Option>
                        )
                      })}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label="Attendance Date & Time"
                    validateStatus={ !attendanceDateTime ? "error" : ""}
                    help={ !attendanceDateTime ? "Please set attendance date and time" : ""}
                  >
                    <DatePicker
                      showToday
                      format="YYYY-MM-DD h:mm a"
                      showTime={{ use12Hours: true, format: "h:mm a" }}
                      placeholder="Attendance date and time"
                      value={this.state.attendanceDateTime}
                      onChange={(value) => this.setState({ attendanceDateTime: value })}
                    />
                  </Form.Item>
                  {channels &&
                    <Form.Item label={"Attended through"}>
                      <Select
                        placeholder={"Hook-up channel"}
                        dropdownMatchSelectWidth={false}
                        style={{ width: 240 }}
                        onChange={(value) => {
                          this.setState({ channel: value, otherChannel: '' });
                        }}
                        allowClear={true}
                      >
                        {channels.map(name =>
                          <Option key={name} value={name}>{name.charAt(0).toUpperCase() + name.slice(1)}</Option>
                        )}
                      </Select>
                    </Form.Item>
                  }
                  {this.state.channel === "others" &&
                    <Form.Item label={"Other Channel"}>
                      <Input
                        onChange={e => this.setState({ otherChannel: e.target.value })}
                      />
                    </Form.Item>
                  }
                </Form>
              </Col>
            </Row>
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={12}>
                <Form {...formItemLayout}>
                  <Form.Item {...tailFormItemLayout}>
                    <Button
                      block
                      onClick={this.handleSubmit}
                      type="primary"
                      htmlType="submit"
                      disabled={disableSubmit}
                    >Register</Button>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
            </>
          }
        </div>
      </div>
    );
  }
}

export default AddGatheringAttendee;
