import { Button, Col, DatePicker, Form, message, Row, Select, Spin } from 'antd';
import React, { Component } from 'react';

import moment from 'moment';

import amsAPI from '../../apis/amsAPI';
import * as constants from '../../helpers/constants';

import 'antd/dist/antd.css';
import './Gathering.css';

const { Option } = Select;
const { WeekPicker } = DatePicker;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 12,
      offset: 6,
    },
  },
};


class EditGatheringWeeknum extends Component {
  state = {
    gatherings: [],
    gatheringTypes: [],
    name: '',
    type: '',
    startDateTime: null,
  };

  componentDidMount() {
    this.getRequiredInfoFromAPI();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      this.getRequiredInfoFromAPI();
    }
  }

  getRequiredInfoFromAPI = async () => {
    this.setState({ loading: true });
    this.callApi(`/ams/gathering_types`)
      .then(res => this.setState({ gatheringTypes: res.data, loadingTypes: false }))
      .catch(err => console.log(err));

    const _id = this.props.location.pathname.split('/')[2];
    this.getGathering(_id)
      .then(async res => {
        const { gathering } = res;
        const { name, type, weekNum, startDateTime } = gathering;

        const weekNumYr = weekNum.substring(0, 4);
        const weekNumWk = weekNum.substring(4);
        var modWeekNum = moment(weekNumYr).add(parseInt(weekNumWk) - 1, 'weeks');

        this.setState({
          name, type, startDateTime,
          loading: false,
          weekNum: modWeekNum,
        });

      })
      .catch(err => console.log(err));
  }

  callApi = async (url) => {
    const response = await amsAPI.getUrl(url);
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  getGathering = async (_id) => {
    const response = await amsAPI.getUrl(`/ams/gatherings/${_id}`)
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  handleSubmit = async e => {
    e.preventDefault();
    const _id = this.props.location.pathname.split('/')[2];

    const { weekNum } = this.state;
    const weekNumStr = weekNum.format('YYYYww');

    amsAPI.fetchUrl(`/ams/gatherings/${_id}/weeknum`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        weekNum: weekNumStr,
        weekNumInt: parseInt(weekNumStr),
        userMemberId: this.props.userMemberId,
      }),
    }).then(async res => {
      if (res.status === 200) {
        const body = await res.json();
        message.success(<span>Gathering successfully updated. <strong>{ body.data.ok }</strong> member attendance/s updated.</span>);
        this.props.history.push('/gatherings');
      } else {
        const error = new Error(res.statusText);
        throw error;
      }
    }).catch(err => {
      console.log(err);
      message.error(`Error saving. ${err}`);
    });

  };

  render() {
    const {
      name, type, startDateTime, weekNum,
      loading, gatheringTypes,
    } = this.state;
    const gatheringEntries = [];
    gatheringTypes.forEach(item => {
      gatheringEntries.push([item.code, item.name]);
    })
    const gatheringTypesMap = {};
    gatheringTypes.forEach(item => {
      gatheringTypesMap[item.code] = item.name;
    });
    let disableSubmit = !weekNum;
    const liveOrReplayOptions = Object.entries(constants.liveOrReplay);

    if (loading) {
      return (
        <div className="wrap">
          <div className="extraContent">
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={12} style={{ textAlign: "center" }}>
                <Spin size="large" />
              </Col>
            </Row>
          </div>
        </div>
      )
    }

    return (
      <div className="wrap">
        <div className="extraContent">
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={18}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <h2>Gathering Form</h2>
            </Col>
          </Row>
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={18}>
              <Form {...formItemLayout}>
                <Form.Item
                  label="Gathering:"
                >
                  <Select
                    dropdownMatchSelectWidth={false}
                    style={{ width: 240 }}
                    defaultValue={name}
                    disabled={true}
                  >
                    {gatheringEntries.map(([id, name]) =>
                      <Option key={id} value={id}>{name}</Option>
                    )}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Type:"
                >
                  <Select
                    dropdownMatchSelectWidth={false}
                    style={{ width: 240 }}
                    defaultValue={type}
                    disabled={true}
                  >
                    {liveOrReplayOptions.map(([id, name]) =>
                      <Option key={id} value={id}>{name}</Option>
                    )}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Start Time"
                >
                  <DatePicker
                    format="YYYY-MM-DD h:mm a"
                    showTime={{ use12Hours: true, format: "h:mm a" }}
                    defaultValue={startDateTime ? moment(startDateTime) : null}
                    disabled={true}
                  />
                </Form.Item>
                <Form.Item
                  label="Week Num"
                >
                  <WeekPicker
                    format="YYYYww"
                    onChange={(value) => this.setState({ weekNum: value })}
                    defaultValue={weekNum ? moment(weekNum) : null}
                  />
                </Form.Item>
              </Form>
            </Col>
          </Row>
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={12}>
              <Form {...formItemLayout}>
                <Form.Item {...tailFormItemLayout}>
                  <Button
                    block
                    onClick={this.handleSubmit}
                    type="primary"
                    htmlType="submit"
                    disabled={disableSubmit}
                  >Update</Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default EditGatheringWeeknum;
