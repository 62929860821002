import { Button, Col, DatePicker, Form, Icon, Input, message, Row, Select, Spin, Switch, Tooltip } from 'antd';
import { Component } from 'react';

import _ from 'lodash';
import moment from 'moment';
import * as qs from 'query-string';

import amsAPI from '../../apis/amsAPI';
import * as constants from '../../helpers/constants';
import disablePastDates from '../../helpers/functions';

import 'antd/dist/antd.css';
import './Gathering.css';

const { Option } = Select;
const { WeekPicker } = DatePicker;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 12,
      offset: 6,
    },
  },
};

class LocaleChurchLink extends Component {
  state = {
    isLocaleChurchInputted: false,
    isLinkInputted: false,
  }
  componentDidMount() {
    let { localeLink } = this.props;
    if (!_.isEmpty(localeLink)) {
      if (localeLink.localeChurch) this.setState({ isLocaleChurchInputted: true });
      if (localeLink.link) this.setState({ isLinkInputted: true });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      let { localeLink } = this.props;
      if (!_.isEmpty(localeLink)) {
        if (localeLink.localeChurch) this.setState({ isLocaleChurchInputted: true });
        if (localeLink.link) this.setState({ isLinkInputted: true });
      }
    }
  }

  render() {
    let { localeChurches, unfilteredLocaleChurches, index, localeLink, disabled } = this.props;
    let { isLocaleChurchInputted, isLinkInputted } = this.state;

    localeChurches = unfilteredLocaleChurches;
    /*TODO: filter locale churches
    if (!_.isEmpty(localeLink) && localeLink.localeChurch) {
      localeChurches = unfilteredLocaleChurches;
      localeChurches = [];
      for (let i = 0; i < unfilteredLocaleChurches.length; i++) {
        if (unfilteredLocaleChurches[i]._id === localeLink.localeChurch) {
          localeChurches = [unfilteredLocaleChurches[i]];
          break;
        }
      }
    }
    */
    return (
      <Row type="flex" justify="center">
        <Col xs={24} sm={24} md={24} lg={6}>
        <Form.Item
          validateStatus={isLinkInputted && !isLocaleChurchInputted ? "error" : ""}
          help={isLinkInputted && !isLocaleChurchInputted ? "Please select locale church" : ""}
        >
          <Select
            onChange={selected => {
              const isLocaleChurchInputted = !_.isEmpty(selected);
              this.setState({ isLocaleChurchInputted });
              this.props.handleLocaleChurchSelect(selected, index);
            }}
            style={{ width: "150px" }}
            allowClear={true}
            defaultValue={localeLink ? localeLink.localeChurch : null}
            disabled={disabled}
          >
            {localeChurches && localeChurches.map(item => {
              return (
                <Option key={item._id} value={item._id}>{item.name}</Option>
              )
            })}
          </Select>
        </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={8}>
        <Form.Item
          validateStatus={isLocaleChurchInputted && !isLinkInputted ? "error" : ""}
          help={isLocaleChurchInputted && !isLinkInputted ? "Please input gathering link" : ""}
        >
          <Input
            onChange={e => {
              const isLinkInputted = !_.isEmpty(e.target.value);
              this.setState({ isLinkInputted });
              this.props.handleLinkInput(e.target.value, index);
            }}
            defaultValue={localeLink ? localeLink.link : null}
            style={{ width: 400 }}
            disabled={disabled}
          />
        </Form.Item>
        </Col>
      </Row>
    );
  }
}

let timeout;
let currentValue;

function fetch(value, callback) {
  if (timeout) {
    clearTimeout(timeout);
    timeout = null;
  }
  currentValue = value;

  async function fake() {
    let searchParams = new URLSearchParams({
      q: value,
      roles: 'wrk',
    }).toString();
    amsAPI.getUrl(`/ams/members?${searchParams}`)
      .then(async response => {
        const body = await response.json();
        if (response.status !== 200) throw Error(body.message);
        return body;
      })
      .then(d => {
        if (currentValue === value) {
          callback(d.members);
        }
      });
  }

  timeout = setTimeout(fake, 300);
}

class EditGathering extends Component {
  state = {
    gatherings: [],
    localeChurches: [],
    gatheringTypes: [],
    selectedLocaleChurches: {},
    members: [],
    assignedServants: [],
    name: '',
    type: '',
    startDateTime: null,
    endDateTime: null,
    channels: ['mcgi.tv', 'zoom', 'youtube', 'facebook', 'instagram', 'satellite', 'others'],
    gatheringLink: '',
    isActive: false,
    isMain: false,
    isAutoStart: false,
    isDisplayLocaleLinks: false,
  };

  componentDidMount() {
    this.getRequiredInfoFromAPI();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      this.getRequiredInfoFromAPI();
    }
  }

  getRequiredInfoFromAPI = async () => {
    this.setState({ loading: true });
    this.callApi(`/ams/gathering_types`)
      .then(res => this.setState({ gatheringTypes: res.data, loadingTypes: false }))
      .catch(err => console.log(err));
    this.getLocaleChurches()
      .then(res => {
        this.setState({
          localeChurches: res.locale_churches,
        })

        const _id = this.props.location.pathname.split('/')[2];
        this.getGathering(_id)
          .then(async res => {
            const { gathering } = res;
            const {
              active, autoStart, localeLinks, assignedServants,
            } = gathering;
            if (!_.isEmpty(assignedServants)) {
              let membersRes = await this.getMembers(assignedServants);
              this.setState({ members: membersRes.data });
            }

            const { name, type } = gathering;
            if (type === "replay" && constants.gatheringsWithReplay.includes(name)) {
              let query = { name: name , type: 'live' };
              this.callApi(`/ams/gatherings?${qs.stringify(query)}`)
                .then(res => {
                  let selectedLocaleChurches = {};
                  localeLinks.forEach((item, index) => {
                    const { localeChurch, link } = item;
                    selectedLocaleChurches[index] = { localeChurch, link }
                  })

                  let refWeekNum = null;
                  let refGathering = null;
                  if (gathering?.referenceGathering) {
                    refGathering = gathering.referenceGathering._id;
                    refWeekNum = gathering?.referenceGathering?.startDateTime;
                  }

                  this.setState({
                    ...gathering,
                    gatherings: res.gatherings,
                    isActive: active,
                    isAutoStart: autoStart,
                    loading: false,
                    selectedLocaleChurches,
                    referenceGathering: refGathering,
                    refWeekNum: refWeekNum,
                  });
                })
                .catch(err => console.log(err));
            } else {
              let selectedLocaleChurches = {};
              localeLinks.forEach((item, index) => {
                const { localeChurch, link } = item;
                selectedLocaleChurches[index] = { localeChurch, link }
              })
              this.setState({
                ...gathering,
                isActive: active,
                isAutoStart: autoStart,
                loading: false,
                selectedLocaleChurches,
              });
            }

          })
          .catch(err => console.log(err));
      })
  }

  callApi = async (url) => {
    const response = await amsAPI.getUrl(url);
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  getGathering = async (_id) => {
    const response = await amsAPI.getUrl(`/ams/gatherings/${_id}`)
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  getMembers = async (ids) => {
    this.setState({ loadingMembers: true });
    const response = await amsAPI.getUrl(`/ams/members?memberIds=${ids}`);
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  getLocaleChurches = async () => {
    const response = await amsAPI.getUrl('/ams/locale_churches')
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  handleGatheringChange = name => {
    this.setState({ name })

    let defaultChannels = [];
    const zoomAndLineOnly = [ "nyr", "pmws", "pm", "ws", "pbb", "spbb", "ls", "tg", "spm" ];
    if (zoomAndLineOnly.includes(name)) {
      defaultChannels = ['zoom', 'others'];
    } else if (name === "sbk") {
      defaultChannels = [ 'zoom', 'mcgi.tv', 'instagram', 'youtube', 'satellite' ];
    } else if (name === "aods") {
      defaultChannels = [ "zoom" ];
    } else {
      defaultChannels = ['zoom', 'youtube', 'facebook', 'mcgi.tv', 'instagram', 'others'];
    }

    this.setState({ channels: defaultChannels });

    if (this.state.type === "replay" && constants.gatheringsWithReplay.includes(name)) {
      let query = { name , type: 'live' };
      this.callApi(`/ams/gatherings?${qs.stringify(query)}`)
        .then(res => this.setState({ gatherings: res.gatherings, loadingGatherings: false }))
        .catch(err => console.log(err));
    }
  }

  handleLiveOrReplayChange = value => {
    this.setState({ type: value, liveOrReplay: value, referenceGathering: null, weekNum: null })

    const { name } = this.state;
    if (value === "replay" && constants.gatheringsWithReplay.includes(name)) {
      let query = { name , type: 'live' };
      this.callApi(`/ams/gatherings?${qs.stringify(query)}`)
        .then(res => this.setState({ gatherings: res.gatherings, loadingGatherings: false }))
        .catch(err => console.log(err));
    }
  }

  handleSubmit = async e => {
    e.preventDefault();
    const _id = this.props.location.pathname.split('/')[2];

    const { referenceGathering, refWeekNum, type } = this.state;

    let modWeekNum = null;
    if (type === "live" && this.state.startDateTime) {
      modWeekNum = this.state.startDateTime;
    } else if (type === "replay" && constants.gatheringsWithReplay.includes(this.state.name)) {
      modWeekNum = refWeekNum ? refWeekNum : null;
    }
    const weekNum = moment.utc(modWeekNum).format('YYYYww');

    amsAPI.fetchUrl(`/ams/gatherings/${_id}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name: this.state.name,
        type: this.state.type,
        liveOrReplay: this.state.liveOrReplay,
        scope: this.state.scope,
        weekNum: weekNum,
        weekNumInt: parseInt(weekNum),
        startDateTime: this.state.startDateTime,
        endDateTime: this.state.endDateTime,
        channels: this.state.channels,
        gatheringLink: this.state.gatheringLink,
        active: this.state.isActive ? true : false,
        isMain: this.state.isMain ? true : false,
        autoStart: this.state.isAutoStart ? true : false,
        selectedLocaleChurches: this.state.selectedLocaleChurches,
        userMemberId: this.props.userMemberId,
        assignedServants: this.state.assignedServants,
        referenceGathering: type === "replay" ? referenceGathering : null,
      }),
    }).then(async res => {
      if (res.status === 200) {
        message.success("Gathering successfully updated.");
        this.props.history.push('/gatherings');
      } else {
        const error = new Error(res.statusText);
        throw error;
      }
    }).catch(err => {
      console.log(err);
      message.error(`Error saving. ${err}`);
    });

  };

  handleLocaleChurchSelect = (selection, index) => {
    const { selectedLocaleChurches } = this.state;
    if (_.isEmpty(selectedLocaleChurches[index])) {
      selectedLocaleChurches[index] = {};
    }
    selectedLocaleChurches[index] = {
      link: selectedLocaleChurches[index].link,
      localeChurch: selection,
    };
    this.setState({ selectedLocaleChurches });
  };

  handleLinkInput = (value, index) => {
    const { selectedLocaleChurches } = this.state;
    if (_.isEmpty(selectedLocaleChurches[index])) {
      selectedLocaleChurches[index] = {};
    }
    selectedLocaleChurches[index] = {
      localeChurch: selectedLocaleChurches[index].localeChurch,
      link: value,
    };
    this.setState({ selectedLocaleChurches });
  };

  handleSearch = value => {
    if (value) {
      fetch(value, data => {
        this.setState({ members: data })
      });
    } else {
      this.setState({ members: [] });
    }
  };

  handleChange = assignedServants => {
    this.setState({ assignedServants });
  };

  render() {
    const {
      name, type, isActive, isMain, startDateTime, isAutoStart, endDateTime, gatheringLink,
      loading, localeChurches, selectedLocaleChurches, localeLinks, gatherings, refWeekNum,
      isDisplayLocaleLinks, channels, members, assignedServants, gatheringTypes, referenceGathering,
    } = this.state;
    const gatheringEntries = [];
    gatheringTypes.forEach(item => {
      gatheringEntries.push([item.code, item.name]);
    })
    const gatheringTypesMap = {};
    gatheringTypes.forEach(item => {
      gatheringTypesMap[item.code] = item.name;
    });
    let disableSubmit = !name || !type || _.isEmpty(channels) || !startDateTime || _.isEmpty(assignedServants);

    let modWeekNum = null;
    if (type === "replay" && constants.gatheringsWithReplay.includes(this.state.name)) {
      modWeekNum = refWeekNum ? moment.utc(refWeekNum) : null;
      disableSubmit = disableSubmit || !modWeekNum;
    } else {
      modWeekNum = moment(startDateTime);
    }

    let filteredlocaleChurches = [];
    if (!_.isEmpty(selectedLocaleChurches)) {
      filteredlocaleChurches = localeChurches.filter(item => {
        let hasMatch = false;
        const keys = Object.keys(selectedLocaleChurches);
        for (let i = 0; i < keys.length; i++) {
          if (selectedLocaleChurches[keys[i]].localeChurch === item._id) {
            hasMatch = true;
            break;
          }
        }
        return !hasMatch;
      });
    }

    if (loading) {
      return (
        <div className="wrap">
          <div className="extraContent">
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={12} style={{ textAlign: "center" }}>
                <Spin size="large" />
              </Col>
            </Row>
          </div>
        </div>
      )
    }

    const liveOrReplayOptions = Object.entries(constants.liveOrReplay);
    const channelEntries = Object.entries(constants.channels);
    const isPastDate = moment().isAfter(startDateTime);

    return (
      <div className="wrap">
        <div className="extraContent">
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={18}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <h2>Gathering Form</h2>
            </Col>
          </Row>
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={18}>
              <Form {...formItemLayout}>
                <Form.Item
                  label="Gathering:"
                  validateStatus={ !name ? "error" : ""}
                  help={ !name ? "Please select a gathering" : ""}
                >
                  <Select
                    showSearch
                    placeholder="Select a gathering"
                    dropdownMatchSelectWidth={false}
                    style={{ width: 240 }}
                    onChange={this.handleGatheringChange}
                    defaultValue={name}
                    disabled={isPastDate}
                  >
                    {gatheringEntries.map(([id, name]) =>
                      <Option key={id} value={id}>{name}</Option>
                    )}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Type:"
                  validateStatus={_.isEmpty(type) ? "error" : ""}
                  help={_.isEmpty(type) ? "Please select if live or replay" : ""}
                >
                  <Select
                    showSearch
                    placeholder="Select a gathering type"
                    dropdownMatchSelectWidth={false}
                    style={{ width: 240 }}
                    onChange={this.handleLiveOrReplayChange}
                    defaultValue={type}
                    disabled={isPastDate}
                  >
                    {liveOrReplayOptions.map(([id, name]) =>
                      <Option key={id} value={id}>{name}</Option>
                    )}
                  </Select>
                </Form.Item>
                {this.state.type === "replay" && constants.gatheringsWithReplay.includes(this.state.name) ?
                  <Form.Item
                    label="Reference Gathering"
                    validateStatus={ !referenceGathering ? "error" : ""}
                    help={ !referenceGathering ? "Please select a reference gathering" : ""}
                  >
                    <Select
                      showSearch allowClear
                      placeholder="Select a gathering"
                      dropdownMatchSelectWidth={false}
                      style={{ width: 400 }}
                      value={referenceGathering}
                      onChange={value => {
                        this.setState({ referenceGathering: value });
                        if (value) {
                          this.getGathering(value)
                          .then(async res => {
                            const { data } = res;
                            this.setState({ refWeekNum: data.startDateTime });
                          })
                        }
                      }}
                    >
                      {gatherings.map(item =>
                        <Option key={item._id} value={item._id}>
                          {moment(item.startDateTime).format("MMM.DD(ddd), YYYY h:mmA")} {gatheringTypesMap[item.name]} ({item.type})
                        </Option>
                      )}
                    </Select>
                  </Form.Item>
                  :
                  null
                }
                <Form.Item
                  placeholder={"Input member name or id"}
                  label="Assiged Servant"
                  validateStatus={_.isEmpty(assignedServants) ? "error" : ""}
                  help={_.isEmpty(assignedServants) ? "Please input assigned servant" : ""}
                >
                  <Select
                    showSearch
                    placeholder={"Input member name or id"}
                    dropdownMatchSelectWidth={false}
                    style={{ width: 240 }}
                    optionFilterProp="value"
                    defaultActiveFirstOption={false}
                    showArrow={false}
                    filterOption={false}
                    onSearch={this.handleSearch}
                    onChange={this.handleChange}
                    notFoundContent={null}
                    value={assignedServants}
                    allowClear={true}
                    mode="multiple"
                  >
                    {members.map(item => {
                      return (
                        <Option key={item._id} value={item._id}>
                          {`${item.name}`}
                        </Option>
                      )
                    })}
                  </Select>
                </Form.Item>
                <Form.Item label="Scope">
                  <Select
                    dropdownMatchSelectWidth={false}
                    style={{ width: 240 }}
                    value={this.props.scope.charAt(0).toUpperCase() + this.props.scope.substring(1)}
                    disabled={true}
                  >
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Start Time"
                  validateStatus={ !startDateTime ? "error" : ""}
                  help={ !startDateTime ? "Please input start date and time" : ""}
                >
                  <DatePicker
                    showToday
                    format="YYYY-MM-DD h:mm a"
                    showTime={{ use12Hours: true, format: "h:mm a" }}
                    placeholder="Starting date and time"
                    onChange={(value) => this.setState({ startDateTime: value })}
                    disabledDate={disablePastDates}
                    defaultValue={startDateTime ? moment(startDateTime) : null}
                    disabled={isPastDate}
                  />
                </Form.Item>
                <Form.Item
                  label= {
                    <span>
                      Is Auto-Open&nbsp;
                      <Tooltip
                        title="Gathering will auto-start or open 1 hour before the specified start date and time"
                        placement="right"
                        zIndex={1}
                      >
                        <Icon type="question-circle-o" />
                      </Tooltip>
                    </span>
                  }
                >
                  <Switch
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                    defaultChecked={isAutoStart}
                    onChange={(checked) => this.setState({ isAutoStart: checked })}
                  />
                </Form.Item>
                <Form.Item label="Close Time">
                  <DatePicker
                    showToday
                    format="YYYY-MM-DD h:mm a"
                    showTime={{ use12Hours: true, format: "h:mm a" }}
                    placeholder="Closing date and time"
                    onChange={(value) => this.setState({ endDateTime: value })}
                    disabledDate={disablePastDates}
                    defaultValue={endDateTime ? moment(endDateTime) : null}
                  />
                </Form.Item>
                <Form.Item
                  label="Channels"
                  validateStatus={_.isEmpty(channels) ? "error" : ""}
                  help={_.isEmpty(channels) ? "Please select channels" : ""}
                >
                  <Select
                    placeholder="Please select channels"
                    dropdownMatchSelectWidth={false}
                    style={{ width: 240 }}
                    onChange={(value) => this.setState({ channels: value })}
                    allowClear={true}
                    mode={"multiple"}
                    value={channels}
                  >
                    {channelEntries.map(([id, name]) =>
                      <Option key={id} value={id}>{name}</Option>
                    )}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Gathering Link:"
                >
                  <Input
                    onChange={(e) => this.setState({ gatheringLink: e.target.value })}
                    defaultValue={gatheringLink}
                    style={{ width: 500 }}
                  />
                </Form.Item>
                <Form.Item label="Is active?">
                  <Switch
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                    defaultChecked={isActive}
                    onChange={(checked) => this.setState({ isActive: checked })}
                  />
                </Form.Item>
                <Form.Item label="Is Main?">
                  <Switch
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                    defaultChecked={isMain}
                    onChange={(checked) => this.setState({ isMain: checked })}
                  />
                </Form.Item>
                <Form.Item
                  label="Week Num"
                >
                  <WeekPicker
                    format="YYYYww"
                    value={modWeekNum ? modWeekNum : null}
                    allowClear={false}
                    open={false}
                  />
                </Form.Item>
                <Form.Item label="Input Locale Links?" colon={false} >
                  <Switch
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                    onChange={(checked) => this.setState({ isDisplayLocaleLinks: checked })}
                    disabled={isPastDate}
                  />
                </Form.Item>
              </Form>
            </Col>
          </Row>
          {isDisplayLocaleLinks &&
            <div>
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={18}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <h3>Locale Gathering Links</h3>
              </Col>
            </Row>
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={12}>
                <Form layout="inline">
                  {_.range(this.state.localeChurches.length).map(i => {
                    return (
                      <LocaleChurchLink
                        localeChurches={filteredlocaleChurches}
                        unfilteredLocaleChurches={localeChurches}
                        handleLocaleChurchSelect={this.handleLocaleChurchSelect}
                        handleLinkInput={this.handleLinkInput}
                        localeLink={localeLinks[i]}
                        index={i}
                        key={i}
                        disabled={isPastDate}
                      />
                    )
                  })}
                </Form>
              </Col>
            </Row>
            </div>
          }
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={12}>
              <Form {...formItemLayout}>
                <Form.Item {...tailFormItemLayout}>
                  <Button
                    block
                    onClick={this.handleSubmit}
                    type="primary"
                    htmlType="submit"
                    disabled={disableSubmit}
                  >Update</Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default EditGathering;
