import React, { Component } from 'react';
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { Button, Col, Row, Spin, Table, message, Input, Icon } from 'antd';
import moment from 'moment';
import * as qs from 'query-string';
import Highlighter from 'react-highlight-words';

import amsAPI from '../../apis/amsAPI';

import 'antd/dist/antd.css';
import './List.css';

class GatheringAttendees extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      gathering: {},
      members: [],
      localeChurchNames: [],
      loadingMembers: false,
    };
  }

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  componentDidMount() {
    this.getAttendees()
      .then(res => this.setState({ members: res.attendees, loadingMembers: false }))
      .catch(err => console.log(err));

    const { gatheringId } = this.props.match.params;
    this.getGathering(gatheringId)
      .then(res => this.setState({ gathering: res.gathering }))
      .catch(err => console.log(err));
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      this.getAttendees()
        .then(res => this.setState({ members: res.attendees, loadingMembers: false }))
        .catch(err => console.log(err));
    }
  }

  getAttendees = async () => {
    const { userMemberId, localeChurchId, groupName } = this.props;
    this.setState({ loadingMembers: true });
    const { gatheringId } = this.props.match.params;
    const q = {
      localeChurch: localeChurchId,
      groupName,
      userMemberId,
    };

    const response = await amsAPI.getUrl(`/ams/gatherings/${gatheringId}/attendees?${qs.stringify(q)}`);
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  getGathering = async (_id) => {
    const response = await amsAPI.getUrl(`/ams/gatherings/${_id}`)
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  refresh = () => {
    this.getAttendees()
      .then(res => this.setState({ members: res.attendees, loadingMembers: false }))
      .catch(err => console.log(err));
  }

  handleDelete = async (_id) => {
    await amsAPI.fetchUrl(`/ams/member_attendance/${_id}`, {
      method: 'DELETE',
    });
    message.success("Attendance successfully removed.");
    const { gatheringId } = this.props.match.params;
    this.props.history.push(`/gatherings/${gatheringId}/attendees`);
  };

  render() {
    const columns = [
      {
        title: 'Name',
        dataIndex: 'memberId',
        key: 'memberId',
        sorter: (a, b) => a.memberId.name.localeCompare(b.memberId.name),
        render: (text, record) => (
          <span>
            {(record.memberId && record.memberId.name) ? record.memberId.name : ""}
          </span>
        ),
      },
      {
        title: 'Locale Church',
        dataIndex: 'memberId.localeChurchId',
        key: 'memberId.localeChurchId',
        render: (text, record) => (
          <span>
            {(record.memberId && record.memberId.localeChurchId) ? record.memberId.localeChurchId.name : ""}
          </span>
        ),
      },
      {
        title: 'Submission',
        dataIndex: 'submissionDateTime',
        key: 'submissionDateTime',
        render: (text, record) => (
          <span>
            {record.submissionDateTime ?
              `${moment(record.submissionDateTime).format("MMM.DD(ddd), h:mmA")}`
              :
              null
            }
          </span>
        ),
      },
      {
        title: 'IP Address',
        dataIndex: 'ipAddress',
        key: 'ipAddress',
        render: (text, record) => (
          <span>
            {record.ipAddress ?
              record.ipAddress
              :
              null
            }
          </span>
        ),
        ...this.getColumnSearchProps('ipAddress'),
      },
      {
        title: 'Receipt #',
        dataIndex: 'receiptNumber',
        key: 'receiptNumber',
        render: (text, record) => (
          <span>{record.receiptNumber}</span>
        ),
      },
      {
        title: 'Guest/s',
        dataIndex: 'guestAttendance',
        key: 'guestAttendance',
        render: (text, record) => (
          <span>
            {record.guestAttendance.map(item => {
              return <div>{item.religion + " : " + item.count}</div>
            })}
          </span>
        ),
      },
    ];

    const { members, loadingMembers, gathering } = this.state;
    let modResult = [];
    let i = 0;
    members.forEach(item => {
      i++;
      modResult.push({ ...item, key: i, rowKey: { _id: item._id, rowNum: i } });
    });
    const gatheringStr = moment(gathering.startDateTime).format("MMM.DD, YYYY (ddd) h:mmA")

    return (
      <div className="wrap">
        <div className="extraContent">
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={12}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <h3>
                Gatherings Attendees Page<br/>
                {gatheringStr}
              </h3>
            </Col>
          </Row>
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={12}>
              <Button
                type="primary"
                onClick={this.refresh}
              > <Icon type="reload"/></Button>
            </Col>
          </Row>
          {loadingMembers ?
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={12} style={{ textAlign: "center" }}>
                <Spin size="large" />
              </Col>
            </Row>
          :
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={12}>
              {(members.length === 0) ?
                <div>
                  <h3>{`Sorry, but there are no approved attendees for this gathering.`}</h3>
                </div>
              :
                <div>
                  <h3>{`Here are the list of attendees for :`}</h3>
                  <Table pagination={false} columns={columns} dataSource={modResult} />
                </div>
              }
              </Col>
            </Row>
          }
        </div>
      </div>
    );
  }
}

export default withRouter(GatheringAttendees);
