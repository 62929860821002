import { Button, Col, DatePicker, Form, Row, Select, Spin, Table } from 'antd';
import PropTypes from "prop-types";
import { stringify } from 'query-string';
import { Component } from 'react';
import { withRouter } from "react-router";

import moment from 'moment';

import amsAPI from '../../apis/amsAPI';

import 'antd/dist/antd.css';
import './List.css';

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 },
  },
};

const columns = [
  {
    title: 'No',
    dataIndex: 'key',
    key: 'key',
    render: (text, record) => (
      <span>{record.key + 1}</span>
    ),
  },
  {
    title: 'Meeting ID',
    dataIndex: 'id',
    key: 'meeting_id',
    width: 200,
    render: (text, record) => (
      <span>{record.object.id}</span>
    ),
  },
  {
    title: 'User Name',
    key: 'user_name',
    width: 200,
    render: (text, record) => (
      <span>{record.object.participant.user_name}</span>
    ),
  },
  {
    title: 'Topic',
    key: 'topic',
    render: (text, record) => (
      <span>{record.object.topic}</span>
    ),
  },
  {
    title: 'Email',
    key: 'email',
    render: (text, record) => (
      <span>{record.object.participant.email}</span>
    ),
  },
  {
    title: 'Event',
    dataIndex: 'event',
    key: 'event',
    render: event => <span>{event}</span>
  },
  {
    title: 'Join Time',
    key: 'join_time',
    sortDirections: ['descend'],
    render: (text, record) => {
      let join_time = record.object.participant.join_time;
      return (
        <span>
          {join_time ? moment.utc(join_time).local().format("MMM.DD h:mmA") : "-"}
        </span>
      )
    }
  },
  {
    title: 'Leave Time',
    key: 'leave_time',
    sortDirections: ['descend'],
    render: (text, record) => {
      let leave_time = record.object.participant.leave_time;
      return (
        <span>
          {leave_time ? moment.utc(leave_time).local().format("MMM.DD h:mmA") : "-"}
        </span>
      )
    }
  },
  {
    title: 'Leave Reason',
    key: 'leave_reason',
    render: (text, record) => (
      <span>{record.object.participant.leave_reason}</span>
    ),
  },
];
class ZoomAuditLogs extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      gatheringDate: '',
      meetingIds: [],
      zoom_logs: [],
      loading: false,
    };
  }

  componentDidMount() {
    this.setState({ loading: true });

    let query = { };
    if (this.props.scope) query.scope = this.props.scope;

    this.setState({ loading: true });
    this.callApi(`/ams/zoom/audit?${stringify(query)}`)
      .then(res => this.setState({ zoom_logs: res.data, loading: false }))
      .catch(err => console.log(err));

    this.callApi(`/ams/zoom/mtg_ids_a?${stringify(query)}`)
      .then(res => this.setState({ meetingIds: res.data, loading: false }))
      .catch(err => console.log(err));

    let query_g = { limit: 100 };
    if (this.props.scope) query_g.scope = this.props.scope;

  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      let query = { };
      if (this.props.scope) query.scope = this.props.scope;
      if (this.state.meetingIds.length > 0) {
        query.meetingIds = this.state.meetingIds;
      }
      console.log('query', query)

      this.setState({ loading: true });
      this.callApi(`/ams/zoom/audit?${stringify(query)}`)
        .then(res => this.setState({ zoom_logs: res.data, loading: false }))
        .catch(err => console.log(err));

      this.callApi(`/ams/zoom/mtg_ids_a?${stringify(query)}`)
        .then(res => this.setState({ meetingIds: res.data, loading: false }))
        .catch(err => console.log(err));

      let query_g = { limit: 100 };
      if (this.props.scope) query_g.scope = this.props.scope;

    }
  }

  callApi = async (url) => {
    const response = await amsAPI.getUrl(url);
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  handleFilter = async () => {
    const query = {
      gatheringDate: this.state.gatheringDate,
      meetingIds: this.state.meetingIds,
    };
    if (this.props.scope) query.scope = this.props.scope

    console.log('query', query);
    this.callApi(`/ams/zoom/audit?${stringify(query)}`)
      .then(res => this.setState({ zoom_logs: res.data, loading: false }))
      .catch(err => console.log(err));
  };

  downloadTxtFile = async () => {
    const { zoom_logs } = this.state;
    let modResult = [];
    if (zoom_logs && zoom_logs.length > 0) {
      let i = 0;
      zoom_logs.forEach(item => {
        modResult.push({ ...item, key: i++ });
      });
    }

    /*
    let csvRows = [];
    const csvHeader = "No,Name,Gathering,Date & Time,Topic,Email,Join Time,Leave Time";
    csvRows.push(csvHeader);

    let counter = 1;
    modResult.forEach(item => {
      const { user_name, gathering, topic, email, join_time, leave_time } = item;

      const gatheringDesc = gathering ? gatheringTypesMap[gathering.name] : "";
      const gatheringDateTime = gathering ? `${moment(gathering.startDateTime).format("MMM-DD-YYYY (ddd) h:mmA")}` : "";
      const joinTime = join_time ? moment.utc(join_time).local().format("MMM.DD h:mmA") : "";
      const leaveTime = leave_time ? moment.utc(leave_time).local().format("MMM.DD h:mmA") : "";
      const csvRow = `${counter},${user_name},${gatheringDesc},${gatheringDateTime},${topic},${email},${joinTime},${leaveTime}`;

      csvRows.push(csvRow);
      counter++;
    });

    const element = document.createElement("a");
    const file = new Blob([csvRows.join('\n')], {type: 'text/csv'});
    element.href = URL.createObjectURL(file);

    // Save the Data
    const fileName = `Zoom Logs_${moment().format('YYYYMMDD_hhmmss')}.csv`;
    element.download = fileName;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
    */
  }

  render() {
    const { gatheringDate, zoom_logs, meetingIds, loading } = this.state;
    let modResult = [];
    if (zoom_logs && zoom_logs.length > 0) {
      let i = 0;
      zoom_logs.forEach(item => {
        modResult.push({ ...item, key: i++ });
      });
    }
    console.log('modResult', modResult)
    const hasResult = modResult.length > 0

    return (
      <div className="wrap">
        <div className="extraContent">
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={12}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <h3>Zoom Logs Page</h3>
            </Col>
          </Row>
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={12}>
              <Form {...formItemLayout}>
                <Form.Item label="Meeting ID:">
                  <Select
                    showSearch
                    placeholder="Select Meeting ID"
                    dropdownMatchSelectWidth={false}
                    onChange={value => {
                      console.log('value', value)
                      this.setState({ meetingIds: value })
                    }}
                    mode="multiple"
                  >
                    {meetingIds && meetingIds.map(item =>
                      <Option key={item} value={item}>{item}</Option>
                    )}
                  </Select>
                </Form.Item>
                <Form.Item label="Date:">
                  <DatePicker
                    showToday
                    format="YYYY-MM-DD"
                    onChange={(date, dateString) => this.setState({ gatheringDate: dateString})}
                    defaultValue={gatheringDate ? moment(gatheringDate) : null}
                  />
                  <Button
                    type="primary"
                    style={{ margin: '10px' }}
                    onClick={this.handleFilter}
                  > Filter</Button>
                  <Button
                    type="primary"
                    onClick={this.downloadTxtFile}
                    disabled={!hasResult}
                  >Download</Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
          {loading ?
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={12} style={{ textAlign: "center" }}>
                <Spin size="large" />
              </Col>
            </Row>
          :
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={20}>
              {(zoom_logs.length === 0) ?
                <div style={{textAlign: 'center'}}>
                  <h3>{`Sorry, no zoom logs found.`}</h3>
                </div>
              :
                <div>
                  <h3>{`Here's are the retrieved zoom logs:`}</h3>
                    <Table
                      columns={columns}
                      dataSource={modResult}
                      pagination={{
                        showSizeChanger: true,
                        defaultPageSize: 100,
                        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                        pageSizeOptions: ['100', '500', '1000']
                      }}
                    />
                </div>
              }
              </Col>
            </Row>
          }
        </div>
      </div>
    );
  }
}

export default withRouter(ZoomAuditLogs);