import React, { Component } from 'react';
import { PageHeader, Row, Col, Icon, Card, Badge } from 'antd';
import { NavLink } from 'react-router-dom';

import 'antd/dist/antd.css';
import './Home.css';

class ZoomHome extends Component {

  render() {
    return (
      <PageHeader>
        <div className="wrap">
          <div className="extraContent">
            <Row gutter={16} style={{margin: "10px"}}>
              <Col xs={24} sm={24} md={24} lg={8}>
                <Card style={{display: "flex", justifyContent: "center"}}>
                  <NavLink to="/zoom/logs">
                    <Icon type="profile" style={{ fontSize: '4em', color: '#08c' }} />
                  </NavLink>
                </Card>
              </Col>
              <Col xs={24} sm={24} md={24} lg={8}>
                <Card style={{display: "flex", justifyContent: "center"}}>
                  <NavLink to="/zoom/audit_logs">
                    <Badge count={"Audit"}>
                      <Icon type="profile" style={{ fontSize: '4em', color: '#08c' }} />
                    </Badge>
                  </NavLink>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </PageHeader>

    );
  }
}

export default ZoomHome;